import { createTheme } from '@mui/material/styles';

const adapifyTheme = createTheme({
  palette: {
    primary: {
      light: '#8acec7',
      main: '#349c8e',
      dark: '#286e62',
      contrastText: '#fff',
      gray: '#777',
    },
    secondary: {
      light: '#fdcd8c',
      main: '#fa9c35',
      dark: '#e7742c',
      contrastText: '#fff',
    },
    appBar: {
      lightBg: '#f5f5f5',
      lightFont: 'rgba(0, 0, 0, 0.87)',
      lightBorder: '1px solid #dadada',
      dark: '#484848',
    },
  },
  typography: {
    fontFamily: [
      'Montserrat',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
  },

  // Override MuiTab in theme.js
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '*::-webkit-scrollbar': {
          width: '0.4em',
          height: '0.4em',
          overflow: 'overlay',
        },
        '*::-webkit-scrollbar *': {
          background: 'transparent',
        },
        '*::-webkit-scrollbar-track': {
          display: 'none',
        },
        '*::-webkit-scrollbar-thumb': {
          background: '#a3c755',
          borderRadius: '10px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 2,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 4,
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          borderRadius: '4px 4px 0 0',
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          borderRadius: '0 0 4px 4px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        labelIcon: {
          minHeight: 60,
          paddingTop: 12,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#484848',
          color: '#fff',
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          overflow: 'auto',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          minWidth: '140px',
        },
        body: {
          border: 'none',
        },
        footer: {
          borderTop: '1px solid rgba(224, 224, 224, 1)',
          backgroundColor: '#fff',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&:-webkit-autofill': {
            transitionDelay: '9999s',
            transitionProperty: 'background-color, color',
          },
        },
      },
    },
  },
});

export default adapifyTheme;
